/* eslint-disable complexity */
import React from 'react';
import Moment from 'moment';
import * as yup from 'yup';
import {Formik, Form} from 'formik';
import {Persist} from '../../utils/formik-persist';
import {makeStyles, Paper, Grid, Button, TextField, Select, FormControl,
  InputLabel, MenuItem, CircularProgress, Backdrop} from '@material-ui/core';
import {FormattedMessage, useIntl, FormattedHTMLMessage} from 'react-intl';
import {Blue} from 'wobi-web-common/dist/config/colors.config';
import EzFormControl from 'wobi-web-common/dist/components/EzFormControl';
import {destinationsToNames} from '../../utils/routingHelpers';
import filterCCTypesByProvider from '../../config/cc.types';
import {verifyPayment, producingPolicy, getIsStillWorkingHourOrPolicyStartDateNotBlocked} from '../../utils/apiHandlers';
import {getSelectedOffer, preSaveHandler} from '../../utils/persistOfferDetails';
import {fireGtmEvent} from '../../utils/marketing.utils';
import logos from '../../assets/images/company-logos';
import {formatExpiryDate} from '../../utils/formatters';
import {hebrewCharsRegExp, validateForIsraeliID} from '../../utils/validationHelpers';
import {Breakpoints} from 'wobi-web-common';
import Loader from 'wobi-web-common/dist/components/Loader';
import {getImageByAgent} from '../../config/agent';

const LoaderTravelImage = getImageByAgent('loader-travel.gif');

const DEBIT_CARD_IS_NOT_ALLOWED_CODE = 999;

const Payment = React.memo(({className, offer, initialTabs, submitAct, completeDetailsValues}) => {
  const intl = useIntl();
  const messages = (id) => intl.formatMessage({id});
  const [typeValue, setTypeValue] = React.useState(['']);
  const [submitError, setSubmitError] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isShowLoader, setIsShowLoader] = React.useState(false);

  React.useEffect(() => {
    window.onbeforeunload = () => true;
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const selectedOffer = getSelectedOffer();
  const filteredCCTypes = filterCCTypesByProvider(selectedOffer.provider);
  const classes = useStyles();
  const validationObject = {
    ccType: yup.string().typeError().required('שדה חובה'),
    ccNumber: yup.string().typeError().required('שדה חובה')
      .when('ccType', {
        is: 'American-Express',
        then: yup.string()
          .min(15, 'המספר צריך להיות בן 15 ספרות')
          .max(15, 'המספר צריך להיות בן 15 ספרות')
          .matches(/^\d{15}$/, 'זה צריך להיות רק ספרות'),
        otherwise: yup.string()
          .min(16, 'המספר צריך להיות בן 16 ספרות')
          .max(16, 'המספר צריך להיות בן 16 ספרות')
          .matches(/^\d{16}$/, 'זה צריך להיות רק ספרות'),
      }),
    cvv: yup.string().typeError().required('שדה חובה')
      .min(3, 'מינימום 3 ספרות')
      .max(4, 'מקסימום 4 ספרות')
      .matches(/^\d{3,4}$/, 'זה צריך להיות רק ספרות'),
    ccExpiration: yup.string().typeError('MM/YY שדה חובה').required('שדה חובה')
      .max(5, 'MM/YY שדה חובה')
      .matches(/(\d{2})\/(\d{2})/, 'MM/YY שדה חובה')
      .test('cc', 'שלוש ספרות אחרונות לא תקינות', (value) => {
        if (value) {
          const [month, year] = value.split('/');
          if (year && month) {
            const now = Moment();
            const end = Moment(`${month}/01/${year}`);
            if (end.diff(now, 'days') <= 0) {
              return false;
            }
          }
          return true;
        }
      }),
    ccOwnerName: yup.string()
      .test('fullNameHebrewOnly', 'אנא הזן  טקסט בעברית', (value) => {
        const arrayFromInput = value ? value.split(' ') : [];
        let result = true;
        if (arrayFromInput.length < 2) {
          result = false;
        }

        // make sure every word is in hebrew and not contain or equal empty string or spaces and not . or , in regex
        if (arrayFromInput.some(word => word === '' || word === ' ' || word === '.' || word === ',')) {
          result = false;
        }
        if (arrayFromInput.some(word => /^\d+$/.test(word))) {
          result = false;
        }
        if (arrayFromInput.some(word => /^[A-Za-z]+$/.test(word))) {
          result = false;
        }
        if (arrayFromInput.some(word => /^[\dA-Za-z]+$/.test(word))) {
          result = false;
        }
        if (!result) {
          window.rollbar.error(JSON.stringify({arrayFromInput,
            message: 'ccOwnerName validation failed'}));
          return false;
        }
        const isEveryWordInHebrew = arrayFromInput
          .map(word => hebrewCharsRegExp.test(word))
          .every(word => word === true);
        return isEveryWordInHebrew;
      })
      .required('שדה חובה'),
    ccOwnerId: validateForIsraeliID(),
  };

  const handleChangeCcType = (event, setFieldValue) => {
    setFieldValue('ccType', event.target.value);
    setTypeValue(event.target.value);
  };

  const handleSubmit = async (values, validateForm, setFieldTouched) => {
    setIsSubmitting(true);
    const errors = await validateForm();
    const offersCallId = JSON.parse(sessionStorage.getItem('travel.offersCallId'));

    if (Object.keys(errors).length > 0) {
      Object.keys(validationObject).forEach(field => setFieldTouched(field, true));
      setIsSubmitting(false);
      setIsShowLoader(false);
      return;
    }
    const orderId = Moment().format('mmHHDDMM');
    sessionStorage.setItem('orderId', orderId);
    const selectedOffer = getSelectedOffer();
    if (process.env.REACT_APP_NODE_ENV === 'development') {
      if (selectedOffer.isAutoProduce) {
        if (selectedOffer.provider.toUpperCase().includes('CLAL')) {
          const isStillWorkingHourOrPolicyStartDateNotBlocked = await getIsStillWorkingHourOrPolicyStartDateNotBlocked(initialTabs.policyStartDate);
          setIsShowLoader(!isStillWorkingHourOrPolicyStartDateNotBlocked.data);
        }

        const completeDetails = JSON.parse(sessionStorage.getItem('travel.complete-details'));
        const ccHolder = completeDetails.values ?
          completeDetails.values.firstNameEng + ' ' + completeDetails.values.lastNameEng : '';

        const policyResp = await producingPolicy({
          ...values,
          ccHolder,
          provider: selectedOffer.provider,
          offersCallId,
          orderId,
        });
        // eslint-disable-next-line no-prototype-builtins
        if (policyResp.message === 'Error getting paymentToken') {
          setSubmitError(messages('validation.payment_submit_error'));
          setIsSubmitting(false);
          setIsShowLoader(false);
          return;
        }
        if (!policyResp || (!policyResp.hasOwnProperty('policyID') && !policyResp.ignoreError)) {
          setSubmitError(messages('payment.policy_produce_error'));
          setIsSubmitting(false);
          setIsShowLoader(false);
          return;
        }
      } else {
        const paymentResp = await verifyPayment({
          ...values,
          offersCallId,
          orderId,
        });
        if (paymentResp.statusCode === DEBIT_CARD_IS_NOT_ALLOWED_CODE) {
          setSubmitError(messages('validation.payment_debit_card_not_allowed'));
          setIsSubmitting(false);
          return;
        } else if (!paymentResp || !paymentResp.status) {
          setSubmitError(messages('validation.payment_submit_error'));
          setIsSubmitting(false);
          setIsShowLoader(false);
          return;
        }
      }
    } else {
      const paymentResp = await verifyPayment({
        ...values,
        offersCallId,
        orderId,
      });
      if (paymentResp.statusCode === DEBIT_CARD_IS_NOT_ALLOWED_CODE) {
        setSubmitError(messages('validation.payment_debit_card_not_allowed'));
        setIsSubmitting(false);
        return;
      } else if (!paymentResp || !paymentResp.status) {
        setSubmitError(messages('validation.payment_submit_error'));
        setIsSubmitting(false);
        setIsShowLoader(false);
        return;
      }
      if (selectedOffer.isAutoProduce) {
        if (selectedOffer.provider.toUpperCase().includes('CLAL')) {
          const isStillWorkingHourOrPolicyStartDateNotBlocked =
            await getIsStillWorkingHourOrPolicyStartDateNotBlocked(initialTabs.policyStartDate);
          setIsShowLoader(!isStillWorkingHourOrPolicyStartDateNotBlocked.data);
        }
        const completeDetails = JSON.parse(sessionStorage.getItem('travel.complete-details'));
        const ccHolder = completeDetails.values ?
          completeDetails.values.firstNameEng + ' ' + completeDetails.values.lastNameEng : '';

        const policyResp = await producingPolicy({
          ...values,
          ccHolder,
          provider: selectedOffer.provider,
          offersCallId,
          orderId,
        });
        // eslint-disable-next-line no-prototype-builtins
        if (policyResp.message === 'Error getting paymentToken') {
          setSubmitError(messages('validation.payment_submit_error'));
          setIsSubmitting(false);
          setIsShowLoader(false);
          return;
        }
        if (!policyResp || (!policyResp.hasOwnProperty('policyID') && !policyResp.ignoreError)) {
          setSubmitError(messages('payment.policy_produce_error'));
          setIsSubmitting(false);
          setIsShowLoader(false);
          return;
        }
      }
    }
    sessionStorage.setItem('travel.payment', JSON.stringify({
      ccNumber: values.ccNumber.slice(Math.max(0, values.ccNumber.length - 4)),
    }));
    fireGtmEvent('confirmTravelOrder');
    setIsSubmitting(false);
    setIsShowLoader(false);
    submitAct();
  };

  return (
    <div className={className} data-testid='payment-container'>
      <Formik
        validationSchema={yup.object().shape(validationObject)}
        initialValues={{
          ccType: '',
          ccNumber: '',
          cvv: '',
          ccExpiration: '',
          ccOwnerName: `${initialTabs.firstName1} ${initialTabs.lastName1}`,
          ccOwnerId: initialTabs.ID1,
        }}
        onSubmit={handleSubmit}
      >
        {({values, errors, setFieldValue, validateForm, setFieldTouched, handleChange}) => (
          <Form>
            <Grid container spacing={0}>
              <Grid item xs={12} className={classes.comleteDetailsTitle}>
                <Grid container spacing={0}>
                  <Grid item xs={7} className={`${classes.parentForMiddled}`}>
                    <span className={classes.bottomed}>
                      {destinationsToNames(initialTabs.targetCountries)}
                      {' '}
|
                      {messages('step3.insuredNo.')}
                      {' '}
                      {initialTabs.policyholdNum}
                      {' '}
|
                      {messages('step3.stayOf')}
                      {Number(Moment(initialTabs.policyEndDate).diff(Moment(initialTabs.policyStartDate), 'days')) + 1}
                      {messages('step3.stayOf2')}
                      {' '}
|
                      {offer.productLineName}
                    </span>
                  </Grid>
                  <Grid item xs={5} container justify='flex-end'>
                    <img src={logos[offer.logo]} alt={offer.providerName} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paperOffers} elevation={0}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Paper className={classes.paperSingleOffer} elevation={0}>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            className={classes.parentForMiddled}
                            container
                            justify='center'
                            alignItems='center'
                          >
                            <div className={classes.middled}>
                              <span dir='rtl' className={classes.priceText}>
                                {messages('payment.total_payment_original')}
                                {' '}
                              </span>
                              <span dir='rtl' className={classes.currencySymbol}>
                              $
                                <span className={classes.offerPrice}>{Number.parseFloat(offer.price).toFixed(2)}</span>
                              </span>
                              <br/>
                              <span dir='rtl' className={classes.priceText}>
                                {messages('payment.total_payment')}
                                {' '}
                              </span>
                              <span dir='rtl' className={classes.currencySymbol}>
                              ₪
                                <span className={classes.offerPrice}>{offer.convertedPrice}</span>
                              </span>
                              <br/>
                              <span className={classes.currencyRate} dir='rtl'>
                                {messages('payment.currency_rate_1')}
                                {' '}
                              </span>
                              <span className={classes.currencyRate}>
                                {offer.totalPriceShekel ? Number.parseFloat(offer.totalPriceShekel / offer.price).toFixed(2) : offer.currencyRate}
                                {' '}
                              </span>
                              <br/>
                              <span className={classes.currencyRate} dir='rtl'>
                                {messages('payment.currency_rate_2')}
                              </span>
                            </div>
                          </Grid>
                          <Grid item sm={8} xs={12}>
                            <Backdrop className={classes.backdrop} open={isShowLoader} data-testid='payment-loader'>
                              <Paper style={{padding: 30}}>
                                <Loader
                                  image={LoaderTravelImage}
                                  textClassName={classes.loaderText}
                                  imageContainerClassName={classes.loaderContainer}
                                  imageClassName={classes.loaderImage}
                                >
                                  <div>
                                    <FormattedHTMLMessage id='payment.loading' />
                                  </div>
                                </Loader>
                              </Paper>
                            </Backdrop>
                            <Grid container spacing={0} style={{paddingTop: 10}}>
                              <Grid item sm={6} xs={12}>
                                <EzFormControl
                                  name='ccOwnerName'
                                  label={messages('payment.fullname')}
                                  isLabelInChild
                                >
                                  <TextField
                                    id='outlined-disabled'
                                    margin='normal'
                                    variant='outlined'
                                    data-testid='payment-full-name'
                                    onChange={(e) => {
                                      // remove characters that are  spaces  or . or , and replace multiple spaces with one space
                                      e.target.value = e.target.value.replace(/[^ A-Za-z\u0590-\u05fe]/g, '');
                                      handleChange(e);
                                    }}
                                    value={values.ccOwnerName}
                                  />
                                </EzFormControl>
                              </Grid>
                              <Grid item sm={6} xs={12} style={{paddingLeft: 0}}>
                                <EzFormControl
                                  name='ccOwnerId'
                                  label={messages('payment.israeliId')}
                                  isLabelInChild
                                >
                                  <TextField
                                    id='outlined-disabled'
                                    margin='normal'
                                    variant='outlined'
                                    data-testid='payment-id'
                                    onChange={handleChange}
                                    value={values.ccOwnerId}
                                  />
                                </EzFormControl>
                              </Grid>
                              <Grid item sm={6} xs={12} style={{paddingTop: 35}}>
                                <EzFormControl name='ccType' label={messages('payment.cc_type')} isInputLabel>
                                  <Select
                                    autoFocus
                                    color='primary' style={{width: '100%',
                                      maxWidth: 237}}
                                    value={values.ccType && values.ccType !== typeValue ?
                                      setTypeValue(values.ccType) ? typeValue : typeValue : typeValue}
                                    onChange={(event) => handleChangeCcType(event, setFieldValue)}
                                    data-testid='payment-select-card-type'
                                  >
                                    {filteredCCTypes.map(item => (
                                      <MenuItem
                                        key={item.id}
                                        value={item.id}
                                        data-testid={`payment-card-type-${item.id}`}>
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </EzFormControl>
                              </Grid>
                              <Grid item sm={6} xs={12} style={{paddingLeft: 0,
                                paddingTop: 20}}>
                                <EzFormControl name='ccNumber' label={messages('payment.cc_number')} isLabelInChild>
                                  <TextField
                                    onChange={(event) => setFieldValue('ccNumber', event.target.value)}
                                    value={values.ccNumber}
                                    margin='normal'
                                    variant='outlined'
                                    data-testid='payment-credit-card-number'
                                  />
                                </EzFormControl>
                              </Grid>
                              <Grid item sm={6} xs={12}>
                                <EzFormControl name='cvv' label={messages('payment.cvv')} isLabelInChild>
                                  <TextField name='cvv'
                                    onChange={(event) => setFieldValue('cvv', event.target.value)}
                                    value={values.cvv}
                                    margin='normal'
                                    variant='outlined'
                                    data-testid='payment-credit-card-cvv-number'
                                  />
                                </EzFormControl>
                              </Grid>
                              <Grid item sm={6} xs={12} style={{paddingLeft: 0,
                                paddingTop: 0}}>
                                <EzFormControl name='ccExpiration' label='MM/YY' isLabelInChild>
                                  <TextField
                                    onChange={({target}) => {
                                      if (target.value.length > 5) {
                                        return;
                                      }
                                      setFieldValue('ccExpiration', formatExpiryDate(target.value));
                                    }}
                                    value={values.ccExpiration}
                                    margin='normal'
                                    variant='outlined'
                                    data-testid='payment-credit-card-expiration-date'
                                  />
                                </EzFormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <p className={classes.warning}>
                              <FormattedMessage id='payment.warning' />
                            </p>
                          </Grid>
                          <Grid item xs={12}>
                            <p className={classes.error}>{submitError}</p>
                          </Grid>
                          <Grid item sm={8} xs={12} />
                          <Grid item sm={4} xs={12} style={{paddingLeft: 30}}>
                            <Button className={classes.submitBtn} size='large'
                              onClick={() => handleSubmit(values, validateForm, setFieldTouched)}
                              data-testid='payment-continue'
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? <CircularProgress/> : <FormattedMessage id='continue'/>}
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
});

const useStyles = makeStyles(theme => ({
  root: {},
  comleteDetailsTitle: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(24.5),
    fontWeight: 450,
    margin: '19px 19px 0',
  },
  paperOffers: {
    borderRadius: 8,
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : Blue[150],
    margin: 0,
  },
  submitBtn: {
    fontSize: theme.typography.pxToRem(15.75),
    height: 52,
    width: '150px',
    marginBottom: 20,
  },
  helper: {
    paddingLeft: 14,
  },
  helper2: {
    color: theme.palette.error.main,
  },
  paperSingleOffer: {
    borderRadius: 8,
    backgroundColor: theme.palette.background.default,
    margin: '19px 19px',
    border: '1px solid ' + Blue[100],
    padding: 10,
  },
  parentForMiddled: {
    position: 'relative',
  },
  middled: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 20,
    },
  },
  priceText: {
    fontSize: theme.typography.pxToRem(21),
  },
  currencyRate: {
    fontSize: theme.typography.fontSize,
    fontWeight: 'normal',
    [theme.breakpoints.down(Breakpoints.mobile)]: {
      display: 'flex',
      justifyContent: 'flex-start',
      textAlign: 'initial',
    },
  },
  warning: {
    textAlign: 'center',
  },
  error: {
    textAlign: 'center',
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  bottomed: {
    paddingLeft: 30,
    position: 'absolute',
    bottom: 0,
    fontSize: theme.typography.pxToRem(15.75),
    fontWeight: 'bold',
  },
  offerPrice: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(38.5),
  },
  currencySymbol: {
    color: theme.palette.primary.main,
  },

  backdrop: {
    zIndex: 9,
  },
  loaderText: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(12.2),
    marginTop: 30,
    textAlign: 'center',
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingTop: '50px',
    position: 'relative',
    right: '20px',
    justifyContent: 'center',
    '@media screen and (max-width: 768px)': {
      right: 0,
    },
  },
  loaderImage: {
    maxWidth: '50%',
  },
}));

export default Payment;
